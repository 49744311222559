/* ==========================================================================
Color variables
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$grey-white: #f2f2f2;

$primary: #568ed2;
$secondary: #ffc857; //rgb(231, 142, 7);
$accent: #40b93c;

$fade-grey: #ededed;
$light-grey: #eff4f7;
$title-grey: #a9abac;
$blue-grey: #444f60;
$muted-grey: #999;
$light-blue-grey: #98a9c3;
$medium-grey: #66676b;
$basaltic-grey: #878787;
$purple: #7f00ff;
$mint: #11ffbd;
$bloody: #fc354c;
$pinky: #ff00cc;
$frost: #004e92;
$placeholder: #cecece;
$dark-grey: #344258;
$border-grey: #ccc;
$muted-grey: #999;
$section-grey: #fbfbfb;
