// Import Bulma's core
@import "~bulma/sass/utilities/_all";

@import "~bulma-divider";

// Set your colors
$primary: rgba(23, 105, 164, 0.932);
$primary-invert: findColorInvert($primary);

$violet: #c1677e;
$violet-invert: findColorInvert($violet);

$dark-warning: #c7a42f;
$dark-warning-invert: findColorInvert($violet);

$info: $primary;
$info-invert: $primary-invert;

$snackbar-background-color: $primary;

// Setup $colors to use as bulma classes
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "violet": (
    $violet,
    $violet-invert
  ),
  "dark-warning": (
    $dark-warning,
    $dark-warning-invert
  )
);
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// custom
@import "form";
@import "layout";
@import "table";
@import "page";
