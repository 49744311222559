body {
  // reset navbar fixed-top
  padding-top: 0 !important;
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
 
  .main-content {
    display: flex;
    padding-top: 0rem;
    flex: 1;
  }
  .footer {
    // reset padding (bulma footer)
    padding: 0.3rem;
    background-color: $primary;
    color: white;
  }
}

.btt {
  position: fixed;
  right: 3rem;
  top: calc(100vh - 3rem);
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #ffffff;
  }
}
