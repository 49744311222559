.field {
    &.is-centered {
        justify-content: center;
    }
}

.dd-upload {
    .upload-draggable {
        width: 768px;
    }
    @include mobile {
        &.upload {
            width: 100%;
            .upload-draggable {
                width: 100%;
            }
        }
    }
}

.bulk-action {
    width: 768px;
    @include touch {
        width: 100%;
    }
}

.categoria-dropdown {
    .dropdown-content {
        height: 200px;
        overflow-y: auto;
    }
}

.button {
    @include mobile {
        &.is-mobile {
            width: 100%;
        }
    }
}

.is-action-button {
    border-color: $violet;
    border-width: 1px;
    &:hover {
        background-color: $violet;
        color: white;
    }
}

.is-action-button-reverse {
    border-color: $violet;
    border-width: 1px;
    background-color: $violet;
    color: white;
    &:hover {
        color: black !important;
        background-color: $violet-invert;
    }
}

.field-horizontal-button {
    .field {
        text-align: left;
        @include touch {
            text-align: center;
        }
    }
}