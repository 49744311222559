











































































































































































































































































































































































































































































































































































.landing {
  @import "@/styles/landing/core.scss";

  width: 100%;
}
