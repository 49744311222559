.container {
  &.is-mobile {
    max-width: 768px;
  }
}

.dragzone {
  width: 768px;
  @include mobile {
    width: 100%;
  }
}

.chartContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-content: stretch;
}

.chart {
  @include mobile {
    width: 350px;
  }
  flex-basis: auto;
  margin: 2em;
}

.vincolati {
  color: $primary;
}

.liquidita {
  color: rgb(47, 91, 59);
}

.finanziaria {
  color: rgb(88, 21, 169);
}

.columns {
  &.is-fullwidth {
    width: 100%;
  }
}

.panel-heading {
  background-color: $primary;
  color: white;
}

.strikethrough {
  text-decoration: line-through;
}
