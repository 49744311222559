
.table {
    tr {
        &.is-row-updated {
            background-color: $warning !important;
            transition: 2s ease-out;
        }
        &.is-row-simple {
            transition: 2s ease-out;
        }
    }
    &.is-hoverable {
        tbody {
            tr:not(.is-selected):hover {
                &.is-row-updated {
                    background-color: $warning !important;
                }
            }
        }
    }
}

